<template>
  <el-dialog
    class="more-query-dialog"
    :visible="visible"
    :close-on-click-modal="false"
    :title="`${$t('lang.gles.common.operate')}${$t('lang.gles.stock.enforcementResult')}`"
    :append-to-body="true"
    @close="onClose"
  >
    <m-table
      :table-item="tableItem"
      :table-data="tableData"
      :extend-config="tableExtendConfig"
    >
      <template #moveContainerResult="{ row }">
        <span v-if="row.result">{{ $t('lang.gles.stock.success') }}</span>
        <span v-else class="danger">{{ $t('lang.gles.stock.fail') }}</span>
      </template>
    </m-table>
  </el-dialog>
</template>
<script>
import { getMoveOperateTableData } from '../data'
export default {
  name: 'MoveOperateResult',
  props: {
    queryList: {
      type: Array,
      default: () => []
    }
  },
  provide: {
    rootPage: {
      isPagination: false
    }
  },
  data() {
    return {
      visible: false,
      tableExtendConfig: {
        sortNum: true
      },
      tableData: []
    }
  },
  computed: {
    tableItem() {
      return getMoveOperateTableData(this)
    }
  },
  methods: {
    init(row) {
      this.visible = true
      this.tableData = row
    },
    onClose() {
      this.$emit('getDetailList')
      this.visible = false
    }
  }
}
</script>
<style lang="scss">
.more-query-dialog {
  .query-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .el-form-item {
      flex: 0 0 45%;
      max-width: 45%;
    }
    .el-select {
      width: 100%;
    }
  }
  .el-dialog {
    margin-left: 50%;
  }
  .el-dialog__header {
    border-bottom: 1px solid #eeee;
  }
  .el-dialog__title {
    font-size: 16px;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    text-align: center;
  }
}
</style>
